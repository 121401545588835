import React from "react";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import ImageViewer from "./ImageViewer";
import SearchModal from "./SearchModal";

export default function Layout({
  children,
  style = null,
  className = "",
  headerSpacer = true,
  header = true,
  ...props
}) {
  const [isImage, setOpenedImage] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [isSearchOpen, setSearchOpen] = React.useState(false);
  // const [loading, setLoading] = React.useState(false);

  const layoutClassName =
    style == "dark" ? "bg-[#121212] text-white" : "bg-white text-[#121212]";

  const handleSearchSwitch = () => setSearchOpen(!isSearchOpen);

  return (
    <div className={"layout main " + className + " " + layoutClassName}>
      {header && (
        <Header
          {...props}
          handleSearchSwitch={handleSearchSwitch}
          style={style}
        />
      )}

      {headerSpacer && (
        <div
          className={`h-24 lg:h-32 ${
            style == "dark"
              ? "bg-[#121212] text-white"
              : "bg-white text-[#121212]"
          }`}
        />
      )}

      <MobileMenu {...props} />

      <div className="min-h-[70vh] relative">
        <main className={className}>{children}</main>
      </div>

      <SearchModal
        handleSearchSwitch={handleSearchSwitch}
        isSearchOpen={isSearchOpen}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setOpenedImage={setOpenedImage}
        setSearchOpen={setSearchOpen}
      />

      <ImageViewer projectData={isImage} onCloseCallback={setOpenedImage} />
    </div>
  );
}
